<script>
import ClienteMixin from "components/clientes/components/cliente/include/ClienteMixin"
import ClienteFinanceiroList from "components/clientes/components/cliente/include/Financeiro"

export default {
  name: 'ClienteFinanceiro',
  components: {ClienteFinanceiroList},
  mixins: [ClienteMixin],
}
</script>

<template>
  <div class="scliente-page-pad">
    <cliente-financeiro-list class="m-t fin-cliente" :cliente="cliente" />
  </div>
</template>
